import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Web3ReactProvider } from '@web3-react/core'
import { ethers } from 'ethers'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'

function getLibrary(provider) {
  return new ethers.providers.Web3Provider(provider)
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ChakraProvider>
        <ColorModeScript initialColorMode='dark' />
        <App />
      </ChakraProvider>
    </Web3ReactProvider>
  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
